<template>
  <div class="download-quote-modal">
    <CarriersMarkups ref="carriersMarkups" />
    <div class="d-flex flex-wrap">
      <BuyRate ref="buyRate" class="mr-4 mt-3 mb-2" />
      <ApplyMarkupsInsurance ref="markupsInsurance" class="mt-3 mb-2" />
    </div>
    <div class="carriers-list-container">
      <CarriersModalList
        :carriersList="carriersList"
        ref="carriersModalList"
        class="carriers-list-component mb-2"
      />
    </div>
    <FormatDocumentType v-model="typeOfFormat" />
    <div class="buttons-container">
      <button class="buttons-container__cancel" @click="$emit('closeModal')">Cancel</button>
      <button class="buttons-container__save" @click="downloadQuotesFile()">Download</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CarriersMarkups from "./components/CarriersMarkups.vue";
import FormatDocumentType from "./components/FormatDocumentType.vue";
import BuyRate from "./components/BuyRate.vue";
import ApplyMarkupsInsurance from "./components/ApplyMarkupsInsurance.vue";
import CarriersModalList from "./components/CarriersModalList.vue";
import downloadDocument from "../../../../utils/DownloadDocuments";

export default {
  name: "DownloadQuoteModal",
  props: {
    chosenLoad: Object,
  },
  components: {
    CarriersMarkups,
    FormatDocumentType,
    BuyRate,
    CarriersModalList,
    ApplyMarkupsInsurance
  },
  data() {
    return {
      typeOfFormat: "",
    };
  },
  watch: {
    typeOfFormat: {
      immediate: true,
      handler() {
        this.$emit("input", this.typeOfFormat);
      },
    },
  },
  computed: {
    ...mapGetters({ carriersList: "carriers/getCarriers" }),
  },
  beforeDestroy() {
    this.$store.commit("quoteFile/setMarkups", { markup_min: 0, markup_percent: 0 });
  },
  methods: {
    getQuoteFileForm() {
      const markups = this.$refs.carriersMarkups.getMarkups();
      const quoteFileForm = {
        load_id: this.$route.params.loadId,
        markup_percent: markups.markup_percent,
        markup_min: markups.markup_min,
        exclude_carriers: this.$refs.carriersModalList.getCarriersList(),
        include_buy_rate: this.$refs.buyRate.includeBuyRate,
        markup_insurance: this.$refs.markupsInsurance.applyMarkupsInsurance
      };
      return quoteFileForm;
    },
    async downloadQuotesFile() {
      if (!this.$options.filters.checkPermission("get:quote-file")) {
        return;
      }
      const quoteFileForm = this.getQuoteFileForm();
      if (quoteFileForm.exclude_carriers.length === this.carriersList.length) {
        this.showAlert({
          title: "Please select at least one carrier",
          icon: "warning",
        });
        return;
      }
      this.$emit("handleLoading", true);
      let documentResponse;
      if (this.typeOfFormat === "pdf") {
        documentResponse = await this.$store.dispatch("quoteFile/getQuoteFiles", quoteFileForm);
        downloadDocument(documentResponse, `quote-${this.chosenLoad.name}.pdf`);
      } else {
        documentResponse = await this.$store.dispatch("quoteFile/getQuoteFileExcel", quoteFileForm);
        downloadDocument(documentResponse, `quote-${this.chosenLoad.name}.xlsx`);
      }
      this.$emit("closeModal");
      this.$emit("handleLoading", false);
    },
    showAlert(data) {
      this.swal({
        title: data.title,
        icon: data.icon,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.download-quote-modal {
  font-family: $font-family-portal;
  font-size: 14px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @include font-button-text;
  &__save {
    @include status-button($color-save-button, $color-save-button, $color-primary-text-button);
    width: 45%;
    font-weight: 600 !important;
  }
  &__cancel {
    @include status-button(
      $color-secondary-button,
      $color-secondary-button-border,
      $color-secondary-button-text
    );
    width: 45%;
    font-weight: 600 !important;
  }
}

.carriers-list-container {
  display: flex;
  justify-content: center;
}

.carriers-list-component {
  width: 95%;
}
</style>
